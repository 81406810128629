import React from "react";
import classNames from "classnames";
import styles from "./EmeraldTitle.module.scss";
import { Lock } from "components/Icons";

type Props = {
	title: string;
	slug: string;
	// ownerSlug: string;
	large?: boolean;
	isPublic?: boolean;
	// showPrefix?: boolean;
	className?: string;
};

const EmeraldTitle: React.FC<Props> = function ({
	title,
	slug,
	// ownerSlug,
	large = false,
	isPublic = false,
	// showPrefix = false,
	className = "",
}) {
	return (
		<div className={classNames(styles.wrapper, large && styles.large, className)}>
			{/* {showPrefix && (
				<span className={styles.prefix}>
					<a className="hoverline" href={`/u/${ownerSlug}`}>
						{ownerSlug}
					</a>
					<span className={styles.slash}>/</span>
				</span>
			)} */}
			<a className="hoverline" href={`/e/${slug}`}>
				{title}
			</a>
			{!isPublic && <Lock className={styles.icon} size={large ? 32 : 15} />}
		</div>
	);
};

export default EmeraldTitle;
