import { Member, Emerald, Invitation, User } from "@prisma/client";

type PartialRelease = { id: string; version: string };
type PopulatedEmerald = Emerald & { members: Member[]; releases: PartialRelease[] };
type PopulatedMember = Member & { emerald: PopulatedEmerald };
type PopulatedMemberWithUser = Member & { user: User };

const ownedPermissions = ["release", "collaborate"];
const supportedPermissions = ["view", "comment"];

export const isOwned = (member: Member | Invitation | undefined) => {
	if (!member) {
		return false;
	}
	return ownedPermissions.indexOf(member.permissions) > -1;
};

export const getEmeraldsFromMemberships = (
	memberships: PopulatedMember[] = [],
	getOwned = false,
	getSupported = false
) => {
	return memberships
		.filter((membership) => {
			const isOwned = ownedPermissions.indexOf(membership.permissions) > -1;
			const isSupported = supportedPermissions.indexOf(membership.permissions) > -1;
			return (getOwned && isOwned) || (getSupported && isSupported);
		})
		.map((membership) => {
			const flatMember = { ...membership, emerald: undefined };
			const flatMembers = membership.emerald.members || [flatMember];
			const remappedEmerald = {
				...membership.emerald,
				members: flatMembers,
			};
			return remappedEmerald;
		});
};

export const getActiveMemberFromMembers = (
	members: Member[] = [],
	userId: string = ""
): Member | undefined => {
	return members.find((member) => {
		return member.userId === userId;
	});
};

export const getActivePermissionFromMembers = (members: Member[] = [], userId: string) => {
	const activeMember = getActiveMemberFromMembers(members, userId);
	return activeMember?.permissions || "";
};

export const sortOwnersToFront = (foo: Member, bar: Member) => {
	const fooIsOwner = isOwned(foo);
	const barIsOwner = isOwned(bar);
	if (fooIsOwner && !barIsOwner) {
		return -1;
	}
	if (!fooIsOwner && barIsOwner) {
		return 1;
	}
	if (foo.createdAt < bar.createdAt) {
		return -1;
	}
	if (foo.createdAt > bar.createdAt) {
		return 1;
	}
	return 0;
};

export const getMajorReleaser = (members: PopulatedMemberWithUser[]) => {
	return members.find((member: PopulatedMemberWithUser) => {
		return member.permissions === "release";
	});
};

export const getNumCollaborators = (members: Member[]) => {
	return members.reduce((prev: number, curr: Member) => {
		if (isOwned(curr)) {
			return prev + 1;
		}
		return prev;
	}, 0);
};
